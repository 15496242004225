import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMarket } from '@polestar/web3-core-react';
import { NavigationDrawer, useNavigationDrawer } from '@polestar/component-warehouse-react';

const WrappedDrawer = (): React.ReactElement => {
  const history = useHistory();
  const { closeNavigationDrawer } = useNavigationDrawer();
  const { currentMarket } = useMarket();

  return (
    <>
      <NavigationDrawer
        links={[
          {
            label: 'Search',
            onClick: (e) => {
              e.preventDefault();
              history.push(`/${currentMarket?.locale}/`);
              closeNavigationDrawer();
            }
          },
          {
            label: 'Configuration',
            onClick: (e) => {
              e.preventDefault();
              history.push(`/${currentMarket?.locale}/config`);
              closeNavigationDrawer();
            }
          }
        ]}
      />
    </>
  );
};

export default WrappedDrawer;
