import gql from 'graphql-tag';
import { User } from '~root/src/app/types/User';
import { DocumentTypeConfiguration } from '../../types/DocumentTypeConfiguration';

export interface AdminInformation {
  entityTypes: string[];
  documentTypes: string[];
  adminUser: User;
  documentTypeConfiguration: DocumentTypeConfiguration[];
}

export interface AdminInformationResponse {
  adminInformation: AdminInformation;
}

export const GET_ADMIN_INFO = gql`
  query getAdminInformation {
    adminInformation: getAdminInformation {
      entityTypes
      documentTypes
      adminUser {
        email
        name
        preferredUsername
      }
    }
  }
`;
