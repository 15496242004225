import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export interface AuthorizationLinkConfig {
  datoToken: string;
  apiKey?: string;
  enableImpersonation?: boolean;
  getAuthToken?: (scopes: string[]) => Promise<string>;
}

const getAuthLink = ({ datoToken, apiKey, getAuthToken }: AuthorizationLinkConfig) => {
  return setContext(async (_, context) => {
    let token = '';
    const isDatoContext = context.dato === true;
    const isMsalContext = context.msalConfig ? true : false;

    if (isDatoContext) {
      token = datoToken;
    } else if (isMsalContext && getAuthToken) {
      const request = context.msalConfig.scopes.length ? context.msalConfig.scopes : ['openid'];
      token = await getAuthToken(request);
    }
    return { token, apiKey };
  });
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const { token, apiKey } = operation.getContext();
  operation.setContext(({ headers = {} }) => {
    const authHeaders = {
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      ...(apiKey ? { 'x-api-key': apiKey } : {})
    };

    return {
      headers: {
        ...headers,
        ...authHeaders
      }
    };
  });

  return forward(operation);
});

const httpLink = () => {
  return new ApolloLink((operation: Operation, forward: NextLink) => {
    if (typeof window !== 'undefined' && operation.getContext().dato === true) {
      operation.variables.origin = window.location.origin;
    }
    return forward(operation);
  });
};

export const getAuthorizationLink = ({
  datoToken,
  apiKey,
  getAuthToken
}: AuthorizationLinkConfig) => {
  const auth = getAuthLink({ datoToken, apiKey, getAuthToken });
  const link = httpLink();
  return ApolloLink.from([auth, authMiddleware.concat(link)]);
};
