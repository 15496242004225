import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Translations } from '~root/src/app/types/Translations';
import { TranslationQueryResponse } from '~types/TranslationsQueryResponse';
import { GET_TRANSLATIONS_QUERY } from '~app/apollo/queries/GetTranslations';
import { DatoContext } from './context';

const DatoContextProvider: FC = (props) => {
  const { loading, error, data } = useQuery<TranslationQueryResponse>(GET_TRANSLATIONS_QUERY, {
    context: { dato: true }
  });

  const stateContextValue = {
    loading: loading,
    error: error,
    text: (data && data.documenthub) || ({} as Translations)
  };
  return <DatoContext.Provider {...props} value={stateContextValue} />;
};

export { DatoContext };

export default DatoContextProvider;
