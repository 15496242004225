import React from 'react';

import { MarketSelectorProvider, MarketProvider } from '@polestar/web3-core-react';

/**
 * Implement all the different context provider here, they will be passed to the application
 */
const AppContextProvider = (props: any): React.ReactElement => {
  return (
    <MarketProvider>
      <MarketSelectorProvider {...props} />
    </MarketProvider>
  );
};

export default AppContextProvider;
