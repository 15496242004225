import {
  ApolloCache,
  NormalizedCacheObject,
  ApolloClient,
  ApolloLink,
  InMemoryCache
} from '@apollo/client';
import { getAuthorizationLink, AuthorizationLinkConfig } from './links/authorizationLink';
import { getErrorLink, UrlReplaceLinkConfig } from '@polestar/graphql-client';

import getRuntimeConfig from '~config/index';
import { getUrlReplaceLink } from './links/url-replace-link';

export interface DocHubClientConfig {
  //getAuthToken?: (request: SilentRequest) => Promise<AuthenticationResult>;
  getAuthToken?: (scopes: string[]) => Promise<string>;
}
export interface ClientConfig extends AuthorizationLinkConfig, UrlReplaceLinkConfig {
  cache?: ApolloCache<NormalizedCacheObject>;
}

export const getDefaultDocHubAdminApolloClient = (config: ClientConfig) =>
  new ApolloClient({
    link: ApolloLink.from([
      getAuthorizationLink(config),
      getErrorLink(),
      getUrlReplaceLink(config)
    ]),
    cache: config.cache ? config.cache : new InMemoryCache()
  });

export const getDocHubAdminClient = (docHubConfig: DocHubClientConfig) => {
  const config = getRuntimeConfig();

  // check if we are to create the dato apollo client for the preview site or the regular site
  let datoPreviewToken = '';
  try {
    datoPreviewToken = new URLSearchParams(window.location.search).get('preview') || '';
  } catch (error) {} /* eslint-disable-line no-empty */

  const defaultUrl: string = config.api[config.api.region];
  const clientConfig = {
    defaultUrl,
    datoUrl: datoPreviewToken ? <string>config.dato.previewUrl : <string>config.dato.baseUrl,
    datoToken: datoPreviewToken || <string>config.dato.token,
    getAuthToken: docHubConfig.getAuthToken,
    useGETForQueries: false
  };

  const client = getDefaultDocHubAdminApolloClient(clientConfig);

  return client;
};
