import getRuntimeConfig from '~config/index';
import { RedirectRequest, Configuration } from '@azure/msal-browser';

const config = getRuntimeConfig();

export const msalConfig: Configuration = {
  auth: {
    clientId: config.auth.clientId || '',
    authority: `${config?.auth.authorityUrl}${config.auth.tenantId}` || '',
    redirectUri: config.auth.redirectUrl,
    postLogoutRedirectUri: config.auth.redirectUrl
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const loginRequest: RedirectRequest = { scopes: ['email', 'openid', 'profile'] };
