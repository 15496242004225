import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { MutationHookOptions, useMutation } from '@apollo/client';

const useDocHubAdminMutation = <TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
  scopes: string[] = []
) =>
  useMutation(mutation, {
    ...options,
    context: {
      msalConfig: {
        scopes
      }
    }
  });

export default useDocHubAdminMutation;
