import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client';

const useLazyDocHubAdminQuery = <TData, TVariables = undefined>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
  scopes: string[] = []
) =>
  useLazyQuery(query, {
    ...options,
    context: {
      msalConfig: {
        scopes
      }
    }
  });

export default useLazyDocHubAdminQuery;
