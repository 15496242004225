import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import getRuntimeConfig from '~config/index';
import AppContextProvider from './contexts/AppContextProvider';
import App from './App';
import AdminContextProvider from '~root/src/app/contexts/AdminContext';
import { msalConfig } from '~app/contexts/AuthenticationContext';
import DatoContextProvider from './contexts/DatoContext';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getDocHubAdminClient } from './apollo/apollo-client';

const msalInstance = new PublicClientApplication(msalConfig);
const config = getRuntimeConfig();

// Init Sentry
Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment
});

const getToken = async (scopes: string[]) => {
  let result = '';
  const account = msalInstance.getAllAccounts()[0];
  try {
    let response = await msalInstance.acquireTokenSilent({
      scopes: ['openid'],
      account: account
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const claims: any = response.idTokenClaims;
    if (claims?.exp && new Date(claims?.exp * 1000) < new Date()) {
      response = await msalInstance.acquireTokenSilent({
        scopes: ['openid'],
        account: account,
        forceRefresh: true
      });
    }
    result = response.idToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect({ scopes: scopes, account: account });
    }
  }
  return result;
};

export const renderToDOM = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <ApolloProvider client={getDocHubAdminClient({ getAuthToken: getToken })}>
          <AppContextProvider config={config}>
            <DatoContextProvider>
              <AdminContextProvider>
                <App />
              </AdminContextProvider>
            </DatoContextProvider>
          </AppContextProvider>
        </ApolloProvider>
      </BrowserRouter>
    </MsalProvider>,
    document.getElementById('root')
  );

renderToDOM();

if (typeof module.hot !== 'undefined') {
  module.hot.accept(); /* eslint-disable-line no-undef */
}
