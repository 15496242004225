import React, { useContext } from 'react';
import { Spinner } from '@polestar/component-warehouse-react';
import { DatoContext } from '../../contexts/DatoContext';
import ErrorWithoutImage from '../../components/ErrorWithoutImage';
import { useMsal } from '@azure/msal-react';

const UnauthorizedView = () => {
  const { instance } = useMsal();
  const { loading } = useContext(DatoContext);

  const HandleLogin = () => {
    instance.loginRedirect().catch((e: any) => {
      console.error(e);
    });
  };

  if (loading) return <Spinner data-testid="spinner" />;

  return (
    <ErrorWithoutImage
      title={'Unauthorized'}
      message={'You are not authorized to view this page. Press the button below to login.'}
      buttonLabel={'Login'}
      buttonProps={{
        onClick: () => {
          sessionStorage.clear();
          HandleLogin();
        }
      }}
    />
  );
};

export default UnauthorizedView;
