/* eslint-disable @typescript-eslint/no-explicit-any */
import { split, HttpLink } from '@apollo/client';

export interface UrlReplaceLinkConfig {
  defaultUrl: string;
  datoUrl: string;
  useGETForQueries?: boolean;
  credentials?: 'omit' | 'include' | 'same-origin';
}

export const getUrlReplaceLink = ({
  defaultUrl,
  datoUrl,
  useGETForQueries = true,
  credentials
}: UrlReplaceLinkConfig) =>
  split(
    (operation) => {
      return operation.getContext().dato === true;
    },
    new HttpLink({
      fetch: (uri: string, options: any) =>
        fetch(uri.replace(/__DATO_BASE_URL__/gi, datoUrl), options),
      // make sure the path gets replaced at runtime for each request:
      // ApolloClient is only initialised only at page load (first render)
      uri: '__DATO_BASE_URL__',
      useGETForQueries: useGETForQueries // always use get for Dato queries to utilize cache
    }),
    new HttpLink({
      fetch: (uri: string, options: any) => {
        return fetch(uri.replace(/__API_BASE_URL__/gi, defaultUrl), options);
      },
      // make sure the path gets replaced at runtime for each request:
      // ApolloClient is only initialised only at page load (first render)
      uri: '__API_BASE_URL__',
      useGETForQueries,
      credentials: credentials
    })
  );
