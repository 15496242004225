import React from 'react';
import { User } from '~root/src/app/types/User';
import { DocumentTypeConfiguration } from '../../types/DocumentTypeConfiguration';

export interface AdminContextValues {
  loading: boolean;
  saving: boolean;
  entityTypes: string[];
  documentTypes: string[];
  documentTypeConfiguration: DocumentTypeConfiguration[];
  updateDocumentTypeConfiguration: (
    documentType: string,
    configuration: DocumentTypeConfiguration
  ) => Promise<void>;
  deleteDocument: (id: string, version: number) => Promise<void>;
  user?: User;
}

const AdminContext = React.createContext<AdminContextValues | undefined>(undefined);

export const useAdminContext = (): AdminContextValues => {
  const context = React.useContext(AdminContext);
  if (context === undefined) {
    throw new Error('useAdminContext must be used within a AdminContextProvider');
  }
  return context;
};

export default AdminContext;
