// Application specific configuration (environment based)
// We expose a function instead of an object to make sure dotenv has been called before the config itself is defined
// Otherwise all process.env.XXX are undefined.
const getRuntimeConfig = () => ({
  application: {
    namespace: process.env.APP_NAMESPACE || 'document-hub-admin-front',
    // Define parameters specific to the application itself (environment-based or not)
    baseUrl: process.env.APP_BASE_URL,
    // Domain for authentication cookie
    domain: process.env.APP_DOMAIN
  },
  api: {
    // Define parameters specific to the API (environment-based or not)
    global: <string>process.env.API_GLOBAL_BASE_URL,
    region: <'global'>process.env.API_REGION || 'global'
  },
  auth: {
    authorityUrl: process.env.AUTH_AUTHORITY_URI,
    tenantId: process.env.AUTH_TENANT_ID,
    clientId: process.env.AUTH_CLIENT_ID,
    redirectUrl: process.env.AUTH_REDIRECT_URI
  },
  dato: {
    // Define parameters specific to the content API (environment-based or not)
    assetUrl: process.env.DATO_ASSET_BASE_URL,
    baseUrl: process.env.DATO_BASE_URL,
    previewUrl: process.env.DATO_PREVIEW_BASE_URL,
    token: process.env.DATO_TOKEN
  },
  build: {
    // Define parameters specific to the build environment (most likely environment-based)
    mode: process.env.NODE_ENV, // "development" | "production"
    // crossOriginLoading attribute for webpack (we may want to switch between 'anonymous' and 'use-credentials' based on the stage)
    // https://webpack.js.org/configuration/output/#outputcrossoriginloading
    crossOrigin: process.env.CROSS_ORIGIN || 'anonymous'
  },
  sentry: {
    // Various Sentry parameters
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_STAGE
  }
});

export default getRuntimeConfig;
