import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import loadable from '@loadable/component';
import {
  Header,
  PolestarApp,
  Themes,
  Row,
  Text,
  styled
} from '@polestar/component-warehouse-react';
import { DatoCMSPreviewBanner, ErrorBoundary, AppRouter } from '@polestar/web3-core-react';
import routes from '~routes/definition';
import WrappedDrawer from './components/Drawer';
import UnauthorizedView from './views/UnauthorizedView';
import { useAccount, useMsal } from '@azure/msal-react';
import { hasClaim } from './contexts/utils/user';

const LoadableView = loadable((props: { view: string }) => import(`./views/${props.view}/index`));

const LoadableRouter = () => {
  const getView = (routeDefinition: any, routeProps: RouteComponentProps) => (
    <LoadableView {...routeProps} view={routeDefinition?.view || 'NotFoundView'} />
  );
  const getNotFoundView = (routeDefinition: any, routeProps: RouteComponentProps) => (
    <LoadableView {...routeProps} view="NotFoundView" />
  );

  return <AppRouter routes={routes} getView={getView} getNotFoundView={getNotFoundView} />;
};

const App = (): React.ReactElement => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const hasAccess = hasClaim(account, ['DocumentHubSuperAccess', 'DocumentHubAccess']);

  const UserText = styled(Text)({
    lineHeight: '25px'
  });

  return (
    <PolestarApp theme={Themes.light}>
      <Header
        menuButton={hasAccess}
        actions={
          <Row>
            <UserText>{account?.name}</UserText>
          </Row>
        }
      />
      <WrappedDrawer />
      <ErrorBoundary>{hasAccess ? <LoadableRouter /> : <UnauthorizedView />}</ErrorBoundary>
      <ErrorBoundary>
        <DatoCMSPreviewBanner />
      </ErrorBoundary>
    </PolestarApp>
  );
};

export default App;
