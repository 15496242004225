import gql from 'graphql-tag';

export const GET_TRANSLATIONS_QUERY = gql`
  query getDocumentHubTranslations {
    documenthub {
      documentTypeDescriptions {
        authorisationform
        paymentinvoice
        prepaymentinvoice
        salesinvoice
        salesinvoicebank
        receipt
        b2csalescontract
        b2bsalescontract
        paymentadvice
        personalfinancing
        orderspecification
        driverslicense
        personalid
        powerofattorney
        deliveryacceptance
        certificateofregistration
        authorisationform
        termsandconditions
        registrationreport
        vehicleorder
        tirestoragecertificate
        returninspection
        certificateofconformity
        salesinvoicefspext
        salesinvoicebankext
        registrationinstructions
        registrationcertificate
        vignette705
        bankdocument
        driverslicensefront
        driverslicenseback
        foreignersworkpermit
        insurancecertificate
        previouscarregistrationcertificate
        roadsideassistancereportinitiated
        roadsideassistancereportupdated
        roadsideassistancereportsolved
        servicehistory
        vatexemptionformdiplo
      }
    }
  }
`;
