import React from 'react';
import { ApolloError } from '@apollo/client';
import { Translations } from '~root/src/app/types/Translations';

export interface DatoState {
  loading: boolean;
  error: ApolloError | undefined;
  text: Translations;
}

export const DatoContext = React.createContext<DatoState>({
  loading: true,
  error: undefined,
  text: {} as Translations
});

export const useDatoContext = () => {
  const context = React.useContext(DatoContext);
  if (context === undefined) {
    throw new Error('useDatoContext must be used within a DatoContextProvider');
  }
  return context;
};

export default DatoContext;
