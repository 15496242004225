import { AccountInfo } from '@azure/msal-common';

interface idTokenClaims {
  roles: string[];
}

export const hasClaim = (profile: AccountInfo | null, roles?: string[]): boolean => {
  if (!profile) return false;
  if (!roles) return true;

  const claims = profile.idTokenClaims as idTokenClaims;
  if (!claims || !claims.roles?.length) return false;

  if (claims.roles.some((x) => roles.includes(x))) {
    return true;
  }

  return false;
};
