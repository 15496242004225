import React from 'react';
import {
  Spacer,
  Title,
  GridColumn,
  Grid,
  Section,
  Paragraph,
  PrimaryButton,
  Icons,
  Spacings,
  useMediaQuery,
  Colors,
  Sizes
} from '@polestar/component-warehouse-react';

interface ErrorWithoutImageProps {
  title: string;
  message: string;
  buttonLabel: string;
  buttonProps: any;
}

const ErrorWithoutImage = ({
  title,
  message,
  buttonLabel,
  buttonProps
}: ErrorWithoutImageProps): React.ReactElement => {
  const isDevice = useMediaQuery().queryMatches;
  return (
    <Section>
      <Grid>
        <GridColumn span={{ mobile: 4, tablet: 4, desktop: 4 }}>
          {isDevice.mobile && <Spacer spacing={Spacings.large} />}
          <Title size={Sizes.medium}> {title}</Title>
          <Spacer spacing={Spacings.medium} />
          <Paragraph>{message}</Paragraph>
          <Spacer spacing={Spacings.medium} />
          <PrimaryButton
            trailing={Icons.arrowRight}
            iconColor={Colors.safetyOrange}
            {...buttonProps}
          >
            {buttonLabel}
          </PrimaryButton>
          <Spacer spacing={Spacings.xLarge} />
        </GridColumn>
      </Grid>
    </Section>
  );
};

export default ErrorWithoutImage;
